<template lang="pug">
    div.main-container(:style="{backgroundSize:'cover', backgroundImage:'url(' + require('@/assets/img2/insigne-bg-stroke-grey.png') + ')'} ")
        div.overlay
       
        div.main-content(id="content")
            div.layout-px-spacing
                div.row.mt-3
                    div.col-lg-12.col-md-12.layout-spacing
                        div.widget.box.box-shadow
                            div.widget-header
                                div.row
                                    div.col-xl-12.col-md-12.col-sm-12.col-12.text-center
                                        h4 {{ $t('questionslist.summaruques') }}
                                div.widget-content.widget-content-area
                                    div.col-lg-12.mx-auto

                                        div.table-responsive
                                            table.table.table-bordered.table-hover.mb-4
                                                thead
                                                    tr
                                                        th No.
                                                        th {{ $t('questionslist.question') }}
                                                        th {{ $t('questionslist.condition') }}
                                                        th {{ $t('questionslist.action') }}
                                                
                                                tbody
                                                    tr(v-for="question in questionList")
                                                        td {{ question.iOrder }}
                                                        td {{ question.sQuestionDesc }}
                                                        td {{ question.questionState }}                                        
                                                        td 
                                                            button.btn.btn-sm.btn-success(type="button" @click="goToQuestion(question.idResultQuestion)") {{ $t('btns.btngoquest') }}

                            <!--Inicia row de elementos nuevos-->
                            div.row.mx-auto.text-center
                                div.col-md-4
                                    div
                                        p
                                            svg(xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round").feather.feather-clock
                                                circle(cx="12" cy="12" r="10")
                                                polyline(points="12 6 12 12 16 14")
                                            span(style="padding-left: 5px") {{formattedElapsedTime}}

                                div.col-md-4
                                    
                                
                                div.col-md-4
                                    div.btn-group.mt-n2
                                        button(@click="Continue()").btn.btn-light
                                            template {{ $t('btns.btncontinue') }}

</template>
<script>
/* eslint-disable */
export default {
    name: 'QuestionsList',
    props: ['idExamPool', 'idResult'],
    components: {
    },    
    data() {
        return {
            elapsedTime: 0,
            timer: undefined,
            iTotalTime: 0,
            iUsedTime: 0,

            questionList: [],
        }
    },
    computed: {
        formattedElapsedTime() {
            
            const date = new Date(null);
            date.setSeconds(this.elapsedTime / 1000);
            const utc = date.toUTCString();
            if(this.elapsedTime > this.iTotalTime){
                clearInterval(this.timer);
                this.FinishQuestionary()
                return
            }
            
            return utc.substr(utc.indexOf(":") - 2, 8);
            
        }
    },    
    methods: {
        async getData(){
            await axios.get(apiURL + 'Student/getQuestionsList?idExamPool='+ this.idExamPool + "&idResult=" + this.idResult, { headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + localStorage.userToken
            } })
            .then( (response) => {
                if(response.data.sErrors.length > 0){
                    Swal.fire({
                        icon: 'error',
                        title: 'Error',
                        text: response.data.sErrors[0],
                        footer: ''
                    })
                    return                    
                }
                this.questionList = response.data.questionList
                this.elapsedTime = response.data.iUsedTime * 1000 
                this.start()
                this.iTotalTime = response.data.iTotalTime * 1000
                this.iUsedTime = response.data.iUsedTime
            } )
            .catch( (error)  => {
                Swal.fire({
                    icon: 'error',
                    title: 'Error',
                    text: error,
                    footer: ''
                })
                return                    
            } )

        },

        async goToQuestion(idResultQuestion){

            Swal.fire({
            title: this.$t('questionslist.questionMessage'),
            html: this.$t('questionslist.confirmMessage'),
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6', 
            cancelButtonColor: '#d33',
            confirmButtonText: this.$t('questionslist.yesMessage'),
            cancelButtonText: 'No'
            }).then((result) => {
            if (result.isConfirmed) {
                axios.get(apiURL + 'Student/registerTime?idResult='+ this.idResult + '&iUsedTime=' + this.elapsedTime/1000, { headers: {
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + localStorage.userToken
                } })
                .then( (response) => {
                    if(response.data.sErrors.length > 0){
                        Swal.fire({
                            icon: 'error',
                            title: 'Error',
                            text: response.data.sErrors[0],
                            footer: ''
                        })
                        return                    
                    }
                    router.push('/student/singleQuestion/' + idResultQuestion + '/' + this.idResult + '/' + this.idExamPool)
                } )
                .catch( (error)  => {
                    Swal.fire({
                        icon: 'error',
                        title: 'Error',
                        text: error,
                        footer: ''
                    })
                    return                    
                } ) 
            }
            })            
           
        },

        async Continue(){
            await axios.get(apiURL + 'Student/registerTime?idResult='+ this.idResult + '&iUsedTime=' + this.elapsedTime/1000, { headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + localStorage.userToken
            } })
            .then( (response) => {
                if(response.data.sErrors.length > 0){
                    Swal.fire({
                        icon: 'error',
                        title: 'Error',
                        text: response.data.sErrors[0],
                        footer: ''
                    })
                    return                    
                }

                axios.get(apiURL + 'Student/getUserResultData?idResult=' + this.idResult, { headers: {
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + localStorage.userToken
                } })
                .then( (response) => {
                    if(response.data.sErrors.length > 0){
                        Swal.fire({
                            icon: 'error',
                            title: 'Error',
                            text: response.data.sErrors[0],
                            footer: ''
                        })
                        return                    
                    }

                    console.log(response.data.idPersonExam)
                    router.push('/student/takeExam2/' + response.data.idPersonExam + '/' + response.data.idGroupDetail)
                } )
                .catch( (error)  => {
                    Swal.fire({
                        icon: 'error',
                        title: 'Error',
                        text: error,
                        footer: ''
                    })
                    return                    
                } )            
            } )
            .catch( (error)  => {
                Swal.fire({
                    icon: 'error',
                    title: 'Error',
                    text: error,
                    footer: ''
                })
                return                    
            } )            
        },

        async FinishQuestionary(){
            await axios.post(apiURL + 'Student/finishQuestionaryInQuestionList?idResult=' + this.idResult + '&iTotalTime=' + this.elapsedTime/1000, null, { headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + localStorage.userToken
            } })
            .then( (response) => {
                if(response.data.sErrors.length > 0){
                    Swal.fire({
                        icon: 'error',
                        title: 'Error',
                        text: response.data.sErrors[0],
                        footer: ''
                    })
                    return                    
                } 

                axios.get(apiURL + 'Student/getUserResultData?idResult=' + this.idResult, { headers: {
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + localStorage.userToken
                } })
                .then( (response1) => {
                    if(response.data.sErrors.length > 0){
                        Swal.fire({
                            icon: 'error',
                            title: 'Error',
                            text: response.data.sErrors[0],
                            footer: ''
                        })
                        return                    
                    }

                    console.log('Examen terminado')
                    router.push('/student/Results/' + response1.data.idPersonExam + '/' + response1.data.idGroupDetail)
                } )
                .catch( (error)  => {
                    Swal.fire({
                        icon: 'error',
                        title: 'Error',
                        text: error,
                        footer: ''
                    })
                    return                    
                } )                
            } )
            .catch( (error) => {
                Swal.fire({
                    icon: 'error',
                    title: 'Error',
                    text: error,
                    footer: ''
                })
                return                    
            } )            
        },        

        start() {
            this.timer = setInterval(() => {
                this.elapsedTime += 1000;
            }, 1000);
        },
    },
    mounted() {
       //this.checkPermisions(this.$router.currentRoute.path)
       this.getData()
      
    }
}
</script>